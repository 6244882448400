import HeroSection from "../../templates/HeroSection/HeroSection"
import Approach from "../../templates/Approach/Approach"
import FAQ from "../../templates/FAQ"
import NewsLetter from "../../templates/Contact"
import { Hero, DataThree } from "../../particles/ConsultancyData/ConsultancyInfo"
import { DataOne } from "../../particles/ConsultancyData/FAQ"
import Services from "../../templates/Services"
import ProfileImg2 from "../../../assets/TBCONE.png"
import ProfileImg4 from "../../../assets/Excellence.png"
import SoftwareThree from "../../../assets/SoftwareThree.jpg"
import { FaStar, FaCog, FaChartLine } from 'react-icons/fa';
import { AiOutlineBulb } from 'react-icons/ai';
import { TestimonialTexts } from "../../particles/ConsultancyData/ConsultancyTestimonies"
import Testimonies from "../../organs/Testimonies/Testimonies"
import { DataTwo } from "../../particles/ConsultancyData/ConsultancyInfo"
import planning from "../../../assets/Approach Two/Consulting.png";
import development from "../../../assets/Approach Two/Desgin.png";
import collabration from "../../../assets/Approach Two/Implmentation.png";
import Support from "../../../assets/Approach Two/Moniterring.png";
export const renderIcons = (element) => {
    switch (element) {
        case 0:
            return <FaStar size={15} color="currentColor" />;
        case 1:
            return <FaCog size={15} color="currentColor" />;
        case 2:
            return <FaChartLine size={15} color="currentColor" />;
        case 3:
            return <AiOutlineBulb size={15} color="currentColor" />;
        default:
            return "";
    }
  };
  
export const renderProfileImg = (element) => {
    switch (element) {
        case 0:
            return ProfileImg2 ;
        case 1:
            return ProfileImg4 ;
        default:
            return "";
    }
}
export const renderServiceIcon = (element) => {
    switch (element) {
        case 0:
            return planning;  
        case 1:
            return development;
        case 2:
            return collabration;
        case 3:
            return Support;
        default:
            return "";
    }
  };

export {
    Hero, HeroSection, SoftwareThree, 
    DataThree, Services, TestimonialTexts, Testimonies,
    DataTwo, Approach, FAQ, DataOne, NewsLetter
}