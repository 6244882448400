import HeroSection from "../../templates/HeroSection/HeroSection";
import Services from "../../templates/Services";
import Approach from "../../templates/Approach/Approach";
import FAQ from "../../templates/FAQ";
import { Hero } from "../../particles/Services/ServicesInfo";
import { DataThree } from "../../particles/Services/ServicesInfo";
import { DataOne } from "../../particles/Services/ServicesSol";
import { DataTwo } from "../../particles/Services/ServicesSol";
import Two from "../../../assets/ablak-networking.png";
import planning from "../../../assets/Approach/Planning.png";
import deployment from "../../../assets/Approach Two/deployment.png";
import Moniterring from "../../../assets/Approach Two/Moniterring.png";
import Support from "../../../assets/Approach/Support.png";
import { FaBrain, FaBullseye, FaShieldAlt, FaMoneyBill } from "react-icons/fa";
export const renderIcons = (element) => {
  switch (element) {
    case 0:
      return <FaBrain size={15} />;
    case 1:
      return <FaMoneyBill size={15} />;
    case 2:
      return <FaBullseye size={15} />;
    case 3:
      return <FaShieldAlt size={15} />;
    default:
      return "";
  }
};

export const renderServiceIcon = (element) => {
  switch (element) {
    case 0:
      return planning;
    case 1:
      return deployment;
    case 2:
      return Moniterring;
    case 3:
      return Support;
    default:
      return "";
  }
};
export {
  HeroSection,
  Hero,
  Two,
  Services,
  DataThree,
  FAQ,
  DataOne,
  Approach,
  DataTwo,
};
