import { motion } from "framer-motion";
import { FaCheck } from "react-icons/fa";
import { Text } from "../../atoms/Text";
import { Image } from "../../atoms/Image";
import Bulb from "../../../assets/Bulb.jpg";
import { ServiceTexts } from "../../particles/Services";

const fadeInAnimationVariants = {
  first: {
    x: -100,
  },
  last: {
    x: 0,
  },
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
};
const About = () => {
  return (
    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
      id="ABOUT US"
      className="w-full bg-gradient-to-b from-gray-100 to-gray-100 flex flex-col  pt-[40px] md:pt-[50px] pb-[50px] lg:pb-[0px]"
    >
      <main className="w-[90%] mx-auto flex flex-col gap-12">
        <motion.div
          variants={fadeInAnimationVariants}
          initial="initial"
          whileInView="animate"
          transition={{ duration: 1.2 }}
          viewport={{
            once: true,
          }}
          className="flex flex-col"
        >
          <Text
            as="h1"
            className="uppercase font-semibold font-serif text-2xl md:text-4xl text-[#25C7D5] text-center mb-4"
          >
            about us
          </Text>
          <Text className="w-[40px] mx-auto border-2 border-sold border-[#25C7D5] mb-3"></Text>
          <Text
            as="p"
            className="text-sm md:text-base font-medium text-[#4F717F] w-full"
          >
            Ablak solutions is a provider of innovative information technology
            solutions and services tailored to meet the evolving needs of
            businesses worldwide. we have been committed to delivering
            front-line technology solutions that empower organizations to
            streamline operations, enhance productivity, and achieve their
            strategic goals.
          </Text>
        </motion.div>

        <div style={{ paddingLeft: 320 }}>
          <Text className="w-full flex flex-col sm:flex-row gap-12 ">
            <motion.div
              variants={fadeInAnimationVariants}
              className="w-full sm:w-[40%] h-[300px] md:h-[450px]"
              initial="first"
              whileInView="last"
              viewport={{
                once: true,
              }}
              transition={{ duration: 1.2 }}
            >
              <Image
                image={Bulb}
                className="h-[300px] sm:h-[400px]"
                objectCover="object-fit"
              />
            </motion.div>

            <motion.div
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 1.2 }}
              className="flex flex-col"
            >
              <br />
              <br />

              <Text
                as="p"
                className="text-base font-medium text-[#4F717F]"
              ></Text>
              <ul className=" mt-4 flex flex-col gap-4">
                {ServiceTexts.cards.map((service, index) => (
                  <Text className="flex gap-4 items-center">
                    <Text className="rounded-[50%] flex flex-col items-center justify-center p-2 border-solid border-2 border-[#25C7D5]">
                      <FaCheck color="#25C7D5" />
                    </Text>
                    <Text
                      as="p"
                      className="text-base md:text-lg  font-medium text-[#4F717F]"
                    >
                      {service.firstText}
                    </Text>
                  </Text>
                ))}
              </ul>
            </motion.div>
          </Text>
        </div>
      </main>
    </motion.div>
  );
};
export default About;
