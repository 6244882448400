export const ServiceTexts = {
  firstText: "Featured Solutions",
  secondText: "Experience Our World-Renowned Services",
  cards: [
    {
      firstText: "Software Development ",
      secondText:
        "We offer end-to-end process of conceptualizing, designing, building, testing, and maintaining custom software applications. And solutions tailored to specific business or individual needs.",
    },
    {
      firstText: "IT Consultancy & Training",
      secondText:
        "At Ablak, we specialize in providing technical consultancy services to a diverse clientele, encompassing both local and international organizations. Our expertise is concentrated in three core areas: data collection and analysis, process automation, and standard operating procedure (SOP) designs. ",
    },
    {
      firstText: "Mobile Application Development",
      secondText:
        "Ablak delivers future-focused custom mobile app development solutions. Backed by mobile app developers Our mobile app company strives. to cater to businesses Through simple and complex challenges through innovative mobile applications.",
    },
    {
      firstText: "ERP system",
      secondText:
        "Enhance Business Operations with Comprehensive Enterprise resource planning (ERP) Software in Ethiopia Streamlining Data. Integrated and implementing ERP software is the key to unlocking your team’s full potential. ",
    },
    {
      firstText: "Data Engineering",
      secondText:
        "At Ablak, our Data Engineering services are second to none. We cover the entire spectrum, transforming raw data into robust pipelines. Crafting tools for seamless data access, ensuring data quality, integrating diverse data sources, and helping you harness invaluable insights.",
    },
    {
      firstText: "Networking and Surveillance System",
      secondText:
        "Our enterprise network solutions enhance data transmission network efficiency, reliability, and security across various networks like LANs, Wi-Fi, WANs, and the internet. CCTV solutions involve installing surveillance systems to monitor and record activity in specific areas, capturing real-time video feeds for later review or use for security purposes.",
    },
  ],
};
