import { Button } from "../../atoms/Button";
import { TestimonialTexts } from "../../particles/Testimonies";
import { Card } from "../../molecules/Card";
import ProfileImg1 from "../../../assets/EngidaOne.png";
import ProfileImg2 from "../../../assets/TBCONE.png";
import ProfileImg3 from "../../../assets/Excellence.png";
import { Text } from "../../atoms/Text";

export const renderProfileImg = (element) => {
  switch (element) {
    case 0:
      return ProfileImg2;
    case 1:
      return ProfileImg1;
    case 2:
      return ProfileImg3;
    default:
      return "";
  }
};
const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

export { Button, TestimonialTexts, Card, Text, settings };
