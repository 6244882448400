import { Link, useLocation } from "react-router-dom";
import { Text } from "../../atoms/Text";
import { Image } from "../../atoms/Image";
import { Ablak } from "../Navbar";
import { FaTwitter, FaFacebook, FaTelegram, FaLinkedin } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
const Footer = () => {
  const location = useLocation();
  const hiddenRoute = [
    "/services/Software%20Development",
    "/services/Mobile%20Application%20Development",
    "/services/It%20Consultancy%20&%20Training",
    "/services/ERP%20system",
    "/services/Data%20Engineering",
    "/services/Networking%20Services",
  ];
  const checkRouteElement = hiddenRoute.includes(location.pathname);

  return (
    <footer className="w-full bg-[#F7F9FB] pt-[70px]  border-t-[1.8px] pb-[60px]">
      <section className="max-w-7xl flex flex-col-reverse items-center md:flex-row justify-between mx-[100px] ">
        <Text className="flex flex-col md:flex-row gap-[60px] justify-center">
          <Text className="flex flex-col gap-6 mt-[24px]">
            <h1 className=" uppercase text-2xl font-bold text-[#4F717F]">
              Ablak Solutions
            </h1>
            <p className=" text-sm font-serif text-[#4F717F]">
              22 Next to the Capital Hotel, Addis Ababa, <br />
              Ethiopia Gelila Building, Second Floor
            </p>
            <p className=" text-sm font-serif text-[#4F717F]">
              <span className="font-bold text-base">Phone:</span>+251979060679
            </p>
            <p className=" text-sm font-serif text-[#4F717F]">
              <span className="font-bold text-base">Email: </span>
              support@ablaksolutions.com
            </p>
            <p className=" text-sm font-serif text-[#4F717F]">
              <span className="font-bold text-base">Email: </span>
              info@ablaksolutions.com
            </p>
            <Text className="flex gap-2 cursor-pointer">
              <Text className="flex flex-col items-center justify-center bg-[#25C7D5] rounded-md p-2">
                <FaTwitter color="white" />
              </Text>
              <Text className="flex flex-col items-center justify-center bg-[#25C7D5] rounded-md p-2">
                <FaFacebook color="white" />
              </Text>
              <Text className="flex flex-col items-center justify-center bg-[#25C7D5] rounded-md p-2">
                <FaTelegram color="white" />
              </Text>
              <Text className="flex flex-col items-center justify-center bg-[#25C7D5] rounded-md p-2">
                <FaLinkedin color="white" />
              </Text>
            </Text>
          </Text>
          <div className="flex flex-col mt-[24px]">
            <h1 className=" text-2xl font-bold text-[#4F717F]">
              Useful Links
              <ul>
                <li className=" mt-[12px] flex gap-2 items-center">
                  <IoIosArrowForward size={18} color="#25C7D5" />
                  <a
                    href="#HOME"
                    className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline"
                  >
                    Home
                  </a>
                </li>
                <li className=" mt-[12px] flex gap-2 items-center">
                  <IoIosArrowForward size={18} color="#25C7D5" />
                  <a
                    href="#ABOUT US"
                    className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline"
                  >
                    About Us
                  </a>
                </li>
                {!checkRouteElement && (
                  <li className=" mt-[12px] flex gap-2 items-center">
                    <IoIosArrowForward size={18} color="#25C7D5" />
                    <a
                      href="#SOLUTIONS"
                      className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline"
                    >
                      Solutions
                    </a>
                  </li>
                )}
                {(location.pathname ===
                  "/services/Mobile%20Application%20Development" ||
                  location.pathname ===
                    "/services/It%20Consultancy%20&%20Training" ||
                  location.pathname === "/") && (
                  <li className=" mt-[12px] flex gap-2 items-center">
                    <IoIosArrowForward size={18} color="#25C7D5" />
                    <a
                      href="#TESTIMONIES"
                      className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline"
                    >
                      Testimonies
                    </a>
                  </li>
                )}

                {!checkRouteElement && (
                  <li className=" mt-[12px] flex gap-2 items-center">
                    <IoIosArrowForward size={18} color="#25C7D5" />
                    <a
                      href="#CLIENTS"
                      className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline"
                    >
                      Clients
                    </a>
                  </li>
                )}
                {!(location.pathname === "/") && (
                  <li className=" mt-[12px] flex gap-2 items-center">
                    <IoIosArrowForward size={18} color="#25C7D5" />
                    <a
                      href="#APPROACH"
                      className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline"
                    >
                      Approach
                    </a>
                  </li>
                )}
                {!(location.pathname === "/") && (
                  <li className="mt-[12px] flex gap-2 items-center">
                    <IoIosArrowForward size={18} color="#25C7D5" />
                    <a
                      href="#FAQ"
                      className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline"
                    >
                      FAQ
                    </a>
                  </li>
                )}

                <li className=" mt-[12px] flex gap-2 items-center">
                  <IoIosArrowForward size={18} color="#25C7D5" />
                  <a
                    href="#CONTACT"
                    className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </h1>
          </div>
          <div className="flex flex-col mt-[24px]">
            <h1 className=" text-2xl font-bold text-[#4F717F]">
              Our Services
              <ul>
                <Link
                  to="/services/Software Development"
                  className="mt-[12px] flex gap-2 items-center"
                >
                  <IoIosArrowForward size={18} color="#25C7D5" />
                  <h1 className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline">
                    Software Development
                  </h1>
                </Link>
                <Link
                  to="/services/It Consultancy & Training"
                  className="mt-[12px] flex gap-2 items-center"
                >
                  <IoIosArrowForward size={18} color="#25C7D5" />
                  <h1 className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline">
                    It Consultancy & Training
                  </h1>
                </Link>
                <Link
                  to="/services/Mobile Application Development"
                  className="mt-[12px] flex gap-2 items-center"
                >
                  <IoIosArrowForward size={18} color="#25C7D5" />
                  <h1 className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline">
                    Mobile App Development
                  </h1>
                </Link>
                <Link
                  to="/services/ERP system"
                  className="mt-[12px] flex gap-2 items-center"
                >
                  <IoIosArrowForward size={18} color="#25C7D5" />
                  <h1 className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline">
                    ERP System
                  </h1>
                </Link>
                <Link
                  to="/services/Data Engineering"
                  className="mt-[12px] flex gap-2 items-center"
                >
                  <IoIosArrowForward size={18} color="#25C7D5" />
                  <h1 className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline">
                    Data Engineering
                  </h1>
                </Link>
                <Link
                  to="/services/Networking Services"
                  className="mt-[12px] flex gap-2 items-center"
                >
                  <IoIosArrowForward size={18} color="#25C7D5" />
                  <h1 className="text-base font-serif text-[#4F717F] hover:text-[#6F9FB1] hover:underline">
                    Networking Services
                  </h1>
                </Link>
              </ul>
            </h1>
          </div>
        </Text>
        <div className="mt-[50px] md:mt-[0px] flex flex-col gap-2  justify-center">
          <Image
            image={Ablak}
            className="w-[200px] h-[200px]"
            alt="Ablak Solutions"
          />
        </div>
      </section>
    </footer>
  );
};
export default Footer;
