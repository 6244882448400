export const TestimonialTexts = {
  firstText: "TESTIMONIALS",
  secondText: "What Our Clients Say About Us",
  feedBacks: [
    {
      text: `Our partnership with Ablak is characterized by their unwavering commitment to quality,
              ensuring flawless project execution, 
              which has enhanced our project value and strengthened our reputation as technology leaders.`,
      location: "TBC TECHNOLOGIES",
    },
    {
      text: `Choosing Ablak Solutions was one of the best decisions our company has made. 
            Their mobile app has transformed how we conduct our daily tasks, 
            making everything from project management to communication more efficient and effective. 
            The app's intuitive design and robust features have truly set a new standard for what we expect from mobile technology.`,
      location: "ENGIDA TRAVEL",
    },
    {
      text: `One of the most impressive aspects of working with Ablak Solutions is their commitment
            to continuous improvement. They consistently seek feedback and incorporate our suggestions
            into future updates, demonstrating a level of dedication and care that is rare in today's
            fast-paced tech industr`,
      location: "EXCELLENCE CONSULTANCY",
    },
  ],
};
