export const DataTwo = {
    cardsOne: [
        {
            firstText: "Requirement Gathering and Analysis",
            secondText: "Engage with the client to gather requirements, understand their business processes, and analyze their specific needs for the ERP system."
        },
        {
            firstText: "Solution Design and Customization",
            secondText: "Design a tailored ERP solution based on the gathered requirements. Customize the system to align with the client's business processes and integrate with existing systems, if necessary."
        },
        {
            firstText: "Development and Configuration",
            secondText: "Develop and configure the ERP system according to the design specifications. Implement modules and functionalities that address the client's specific requirements."
        },
       
        {
            firstText: "Deployment and Training",
            secondText: "Deploy the ERP system in the client's environment and provide comprehensive training to their staff. Ensure a smooth transition and empower users to effectively utilize the system."
        }
       ]
}
export const DataOne = {
    cards: [
        {
            firstText: "Why should we choose your company for our ERP system?",
            secondText: "As a leading provider in the industry, we have a proven track record of successfully implementing ERP systems for numerous clients. Our team of experienced professionals understands the intricacies of ERP implementation and customization, ensuring that your system is tailored to meet your specific business needs and objectives. With our expertise and commitment to client satisfaction, we are confident in delivering a seamless and effective ERP solution for your organization."
        }, 
        {
            firstText: "How does your company ensure a smooth transition to the new ERP system?",
            secondText: "We have a well-defined implementation process that encompasses thorough planning, comprehensive training, and continuous support. Our team works closely with your organization to assess your existing systems, develop a tailored implementation strategy, and execute the transition with minimal disruption. We provide extensive training programs to empower your employees and offer ongoing support to ensure a smooth transition and maximize the benefits of the new ERP system."
        }, 
        {
            firstText: "What sets your company's ERP solution apart from competitors?",
            secondText: "Our ERP solution stands out due to its advanced features, scalability, and flexibility. We offer a comprehensive suite of modules that cover various business functions, ensuring that all your critical operations are seamlessly integrated. Our solution is highly customizable, allowing you to adapt it to your unique workflows. Additionally, our system is designed to scale with your business, accommodating growth and evolving requirements, providing a future-proof solution."
        },
        {
            firstText: "How does your company handle data security and privacy?",
            secondText: "We prioritize the security and privacy of your data. Our ERP system includes robust security measures such as data encryption, role-based access controls, and regular data backups. We adhere to industry best practices and comply with relevant data protection regulations. Rest assured, your sensitive business information is handled with the utmost confidentiality and protected against unauthorized access."
        }, 
        {
            firstText: "Can your ERP system integrate with our existing software and systems?",
            secondText: "Absolutely. Our ERP solution is designed to seamlessly integrate with a wide range of third-party applications and systems. Whether you have existing CRM software, e-commerce platforms, or supply chain management tools, we can facilitate smooth data exchange and ensure compatibility. Our integration capabilities eliminate data silos, streamline workflows, and provide a unified view of your organization's operations."
        }
    ]
}