import HeroSection from "../../templates/HeroSection/HeroSection"
import Services from "../../templates/Services"
import Testimonies from "../../organs/Testimonies/Testimonies"
import Approach from "../../templates/Approach/Approach"
import FAQ from "../../templates/FAQ"
import { DataThree } from "../../particles/MobileData/MobileInfo"
import { DataTwo } from "../../particles/MobileData/MobileSolution"
import { DataOne } from "../../particles/MobileData/MobileSolution"
import { TestimonialTexts } from "../../particles/MobileData/MobileSolution"
import {Hero} from "../../particles/MobileData/MobileInfo"
import Two from "../../../assets/MobileTest.jpg"
import { FaStar, FaCog, FaLink, FaWrench } from 'react-icons/fa';
import ProfileImg2 from "../../../assets/TBCONE.png"
import ProfileImg4 from "../../../assets/EngidaOne.png"
import planning from "../../../assets/Approach Two/Consulting.png";
import development from "../../../assets/Approach Two/Desgin.png";
import deployment from "../../../assets/Approach Two/deployment.png";
import Support from "../../../assets/Approach/Support.png";
export const renderServiceIcon = (element) => {
    switch (element) {
        case 0:
            return planning;
        case 1:
            return development;
        case 2:
            return  Support;
        case 3:
            return deployment;
        default:
            return "";
    }
}
export const renderIcons = (element) => {
    switch (element) {
        case 0:
            return <FaStar size={15}/>;
        case 1:
            return <FaCog size={15} />;
        case 2:
            return <FaLink size={15} />;
        case 3:
            return <FaWrench size={15} />;
        default:
            return "";
    }
}
export const renderProfileImg = (element) => {
    switch (element) {
        case 0:
            return ProfileImg2 ;
        case 1:
            return ProfileImg4 ;
        default:
            return "";
    }
}
export {
    HeroSection,Two, Hero, 
    Services, DataThree, Testimonies, TestimonialTexts,
    DataTwo, Approach, FAQ, DataOne
}