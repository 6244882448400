import { motion } from "framer-motion";
import { Text } from "../../atoms/Text";
import { List } from "../../atoms/List";
import { Image } from "../../atoms/Image";
import "./Approach.css"
interface FAQProps {
  DataTwo: {
    cardsOne: {
      firstText: string;
      secondText: string;
    }[];
  };
  renderServiceIcon: (element: number) => string;
}

const Approach = ({ DataTwo, renderServiceIcon }: FAQProps) => {
  return (
    <section
      id="APPROACH"
      className="w-full flex flex-col bg-gradient-to-l from-gray-100 to-gray-200 md:pb-[30px]"
    >
      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
      >
        <main className="w-full  flex flex-col my-12 px-8 ">
          <Text
            as="h2"
            className="w-full text-center font-serif font-meduim text-2xl md:text-3xl mb-[20px]"
          >
          Bringing Your Vision to Life with<br />{" "}
            <span className="font-semibold text-[#25C7D5] italic">
            Professional Excellence!
            </span>
          </Text>
          <ul className="w-full flex flex-col md:flex-row md:gap-4 justify-between items-center md:items-stretch">
            
                {DataTwo.cardsOne.map((card, index) => (
                
                <motion.div
                whileInView={{y:[100, 0], opacity:[0, 1]}}
                transition={{duration: 0.5, delay: index * 0.5}}
                className=" listOne hover:text-white  relative  my-4 flex flex-col  w-full sm:w-[70%] md:w-[50%]  items-center justify-center gap-3 border-solid border-2 rounded-xl bg-white shadow-md hover:shadow-lg px-4  cursor-pointer pb-[20px] md:pb-[20px]"
                key={index}
        >
          <div className="fill-anime">
              
              </div>
          <Image image={renderServiceIcon(index)} className="Image w-[70%] md:w-full" />

          <Text className="listTwo flex flex-col gap-3">
            <Text
              as="h2"
              className="listThree w-full text-center font-serif text-base md:text-xl font-bold text-[#77838f]"
            >
              {card.firstText}
            </Text>
            <Text
              as="p"
              className="w-full text-sm  font-serif"
            >
              {card.secondText}
            </Text>
          </Text>
        </motion.div>
                
    ))}
          </ul>
        </main>
      </motion.div>
    </section>
  );
};

export default Approach;