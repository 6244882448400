import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
// @ts-ignore
import { animateScroll } from "react-scroll";
import NavBar from "./components/organs/Navbar/NavBar";
import Footer from "./components/organs/Footer/Footer";
import Home from "./components/pages/Home/Home";
import Consultancy from "./components/pages/Consulting/Consultancy";
import Software from "./components/pages/Software/Software";
import MobileDevelopment from "./components/pages/Mobile/Mobile";
import ERP from "./components/pages/ERP/ERP";
import Engineering from "./components/pages/Enginnering/Enginnering";
import ManagingServices from "./components/pages/Services/ManagingServices";

function App() {
  const directory = useLocation();

  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0,
    });
  }, [directory.pathname]);

  return (
    <div className="w-full overflow-hidden">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services/Software Development" element={<Software />} />
        <Route
          path="/services/It Consultancy & Training"
          element={<Consultancy />}
        />
        <Route
          path="/services/Mobile Application Development"
          element={<MobileDevelopment />}
        />
        <Route path="/services/ERP system" element={<ERP />} />
        <Route path="/services/Data Engineering" element={<Engineering />} />
        <Route
          path="/services/Networking Services"
          element={<ManagingServices />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
