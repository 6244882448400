import {
    Hero, TestFive, HeroSection,
    renderIcons, DataThree, Services, 
    FAQ, DataOne,  DataTwo, Approach, renderServiceIcon} from "./SoftwareImage";
import NewsLetter from "../../templates/Contact";
const Software = ()=>{
    const word = "Software Application"
    return (
       <>
        <HeroSection Hero={Hero} profile={TestFive}/>
        <Services DataThree={DataThree}  renderIcons={renderIcons} word={word}/>
        <Approach DataTwo={DataTwo} renderServiceIcon={renderServiceIcon}/>
        <FAQ DataOne={DataOne}/>
        <NewsLetter/>
       </>
    )
}
export default Software;