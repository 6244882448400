import {  Two, HeroSection, Hero,
    Services, DataThree, renderIcons, 
    Approach, DataTwo, renderServiceIcon, FAQ, DataOne } from "./ERPInfo"
import NewsLetter from "../../templates/Contact"
const ERP = ()=>{
    const word = "ERP System"
    return (
        <>
        <HeroSection Hero={Hero} profile={Two}/>
        <Services word={word} DataThree={DataThree} renderIcons={renderIcons}/>
        <Approach DataTwo={DataTwo} renderServiceIcon={renderServiceIcon}/>
        <FAQ DataOne={DataOne}/>
        <NewsLetter/>
        </>
    )
}
export default ERP;