import {  Hero, HeroSection, SoftwareThree, 
    DataThree, Services, renderIcons, Testimonies,
    TestimonialTexts, renderProfileImg, renderServiceIcon,
    DataTwo, Approach, FAQ, DataOne, NewsLetter} from "./Consulting"
const Consultancy = ()=>{
    const word = "Technology Consultancy"
    return (
        <>
            <HeroSection Hero={Hero} profile={SoftwareThree} />
            <Services DataThree={DataThree} renderIcons={renderIcons} word={word}/>
            <Testimonies TestimonialTexts={TestimonialTexts} renderProfileImg={renderProfileImg}/>
            <Approach DataTwo={DataTwo} renderServiceIcon={renderServiceIcon}/>
            <FAQ DataOne={DataOne}/>
            <NewsLetter/>
        </>
    )
}
export default Consultancy;