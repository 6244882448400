import { List } from "../atoms/List";
import { motion } from "framer-motion";
import { Text } from "../atoms/Text";

interface FAQProps {
  DataThree: {
    cards: {
      firstText: string;
      secondText: string;
    }[];
  };
  renderIcons: (element: number) => JSX.Element | "";
  word: string;
}

const Services = ({ DataThree, renderIcons, word }: FAQProps) => {
  return (
    <section
      id="WHY US"
      className="w-full flex flex-col bg-gradient-to-b from-gray-100 to-gray-100 pb-[50px] md:pb-[100px]"
    >
      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
      >
        <main className="w-full lg:w-[90%] mx-auto flex flex-col ">
          <Text className="text-center text-[#25C7D5] text-xl md:text-3xl font-serif mt-[80px] mb-[30px] font-bold">
            {`Why Choose Ablak Solutions for ${word}?`}
          </Text>
          <ul className="w-full flex justify-center flex-wrap  gap-6">
            {DataThree.cards.map((card, index) => (
              <motion.div
                whileInView={{ y: [100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.3, delay: index * 0.5 }}
                className="lg:w-[45%] mx-4 md:mx-0"
              >
                <List
                  className=" flex h-full items-center  gap-5 border-solid border-2 rounded-xl bg-white shadow-md hover:shadow-lg py-4 px-2  cursor-pointer"
                  key={index}
                >
                  <Text
                    className={`text-white flex flex-col items-center justify-center  p-4 mt-10 rounded-[50%]  
                                        ${
                                          index === 0
                                            ? "bg-[#007bff]"
                                            : index === 1
                                            ? "bg-[#ffA500]"
                                            : index === 2
                                            ? "bg-[#800080]"
                                            : "bg-[#008080]"
                                        }`}
                  >
                    <div>{renderIcons(index)}</div>
                  </Text>
                  <Text className="flex flex-col gap-3">
                    <Text
                      as="h2"
                      className="w-full  text-center font-serif text-xl md:text-2xl font-bold text-[#77838f]"
                    >
                      {card.firstText}
                    </Text>
                    <Text
                      as="p"
                      className="w-full text-sm md:text-base font-serif"
                    >
                      {card.secondText}
                    </Text>
                  </Text>
                </List>
              </motion.div>
            ))}
          </ul>
        </main>
      </motion.div>
    </section>
  );
};

export default Services;
