export const Hero = {
  firstText: "Technology Consultancy",
  secondText:
    " At Ablak,  your trusted technology consultancy. We specialize in data analysis, process automation, and SOP design for a diverse clientele, both local and international. Let us help you unlock your organization's potential.",
};
export const DataThree = {
  cards: [
    {
      firstText: "Expertise and Experience",
      secondText:
        " Ablak is known for its deep expertise in data collection and analysis, process automation, and SOP design. Our team of professionals possesses a wealth of knowledge and practical experience in these core areas. Clients trust us to deliver effective solutions tailored to their specific requirements.",
    },
    {
      firstText: "Customized Solutions",
      secondText:
        "We pride ourselves on providing personalized and customized solutions to our clients. We take the time to understand their unique challenges and goals, enabling us to develop strategies and implement technologies that address their specific needs. Our tailored approach ensures maximum efficiency and effectiveness.",
    },
    {
      firstText: "Proven Track Record",
      secondText:
        "Ablak has a proven track record of delivering successful technology consultancy projects. We have worked with a diverse range of clients, both local and international, across various industries. Our portfolio showcases our ability to drive positive outcomes and deliver value to our clients.",
    },
    {
      firstText: "Innovation-Driven Approach",
      secondText:
        "We stay at the forefront of technological advancements and industry trends. Our consultants are continuously updating their skills and knowledge to provide innovative and cutting-edge solutions. By leveraging the latest technologies and best practices, we help our clients stay competitive in a rapidly evolving digital landscape.",
    },
  ],
};
export const DataTwo = {
  cardsOne: [
    {
      firstText: " Client Consultation and Assessment",
      secondText:
        "Engage in a thorough consultation with the client to understand their requirements, challenges, and goals. Assess their technology needs and identify areas for improvement or optimization.",
    },
    {
      firstText: "Solution Design and Planning",
      secondText:
        "Design tailored technology solutions based on the client's needs and research findings. Create a strategic plan outlining the recommended approach, implementation steps, and potential outcomes.",
    },
    {
      firstText: "Implementation and Integration",
      secondText:
        "Execute the planned technology solutions by implementing and integrating relevant systems, software, or processes. Ensure smooth transition and compatibility with existing infrastructure.",
    },
    {
      firstText: "Monitoring and Optimization",
      secondText:
        "Continuously monitor the implemented solutions, measure their effectiveness, and gather feedback from the client. Optimize and fine-tune the technology solutions to maximize their impact and deliver ongoing value.",
    },
  ],
};
