export const DataTwo = {
    cardsOne: [
        {
            firstText: "Project  Planning",
            secondText: "Analyze client requirements, goals, and project scope. Plan resources, timelines, and deliverables."
        },
       
        {
            firstText: "Development & Testing",
            secondText: "Start the development process according to the project plan. This includes coding, designing, and implementing the required software or mobile application."
        },
        {
            firstText: "Client  Feedback",
            secondText: "Engage in constant communication with the client throughout the development process. Share progress updates, gather feedback, and address any concerns they may have."
        },
        {
            firstText: "Deployment and Support",
            secondText: " Deploy the software or app to desired platforms. Provide ongoing support, updates, and maintenance. Address post-deployment issues promptly for customer satisfaction."
        }
       ]
}