import TestOne from "../../../assets/TestOne.jpg"
import TestTwo from "../../../assets/TestTwo.jpg"
import TestThree from "../../../assets/TestThree.jpg"
import TestFour from "../../../assets/TestFour.jpg"
import TestFive from "../../../assets/TestFive.jpeg"
import {Text} from "../../atoms/Text";
import { CardOne } from "../../molecules/CardOne";
import { Button } from "../../atoms/Button"
import { Card } from "../../molecules/Card"
import { DataOne } from "../../particles/HeroSection"
export const renderProfileImg = (element) =>{
    switch(element) {
        case 0:
            return TestOne;
        case 1: 
            return TestTwo;
        case 2:
            return TestThree;
        case 3:
            return TestFour;
        case 4:
            return TestFive;
        default:
            return "";
    }
}

export {
    Text, CardOne,
    DataOne, Card,  Button
}