import HeroSection from "../../organs/HeroSection/HeroSection";
import Services from "../../organs/Services/Services";
import Testimonies from "../../organs/Testimonies/Testimonies";
import Clients from "../../organs/Clients/Clients";
import NewsLetter from "../../templates/Contact";
import About from "../../organs/ABOUT/About";
import {TestimonialTexts, renderProfileImg} from "./index"
const Home = ()=> {
    return (
      <>
        <HeroSection/>
        <About/>
        <Services/>
        <Testimonies TestimonialTexts={TestimonialTexts} renderProfileImg={renderProfileImg}/>
        <Clients/>
        <NewsLetter/>
      </>
    )
}

export default Home;