import { useRef } from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { Text, CardOne, DataOne, renderProfileImg, Button } from "./index";
import "./HeroSection.css";

const fadeInAnimationVariants = {
  firstContact: {
    opacity: 0,
    y: 100,
  },
  lastContact: {
    opacity: 1,
    y: 1,
  },
  first: {
    opacity: 0,
    x: 100,
  },
  last: {
    opacity: 1,
    x: 0,
  },
  initial: {
    opacity: 0,
    x: -100,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
};

const HeroSection = () => {
  const sliderRef = useRef<Slider | null>();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
  };

  return (
    <div id="HOME" className="w-full mt-[90px]">
      <Slider
        ref={(slider) => (sliderRef.current = slider)}
        {...settings}
        className="flex justify-center"
      >
        {DataOne.cards.map((card, index) => {
          return (
            <div className="w-full flex overflow-hidden" key={index}>
              <CardOne
                cardClass="h-[500px] md:h-auto"
                cardSrc={renderProfileImg(index)}
              >
                <Text className="flex flex-col  justify-between max-w-5xl mx-auto h-[500px] md:h-[700px] items-center text-center text-white">
                  <Text className="flex flex-col justify-center h-full gap-4 px-2 lg:px-0">
                    <motion.div
                      variants={fadeInAnimationVariants}
                      initial="initial"
                      whileInView="animate"
                      transition={{ duration: 1.2 }}
                      viewport={{
                        once: true,
                      }}
                      className="text-3xl sm:text-4xl md:text-6xl font-serif font-bold"
                    >
                      {card.firstText}
                    </motion.div>
                    <motion.div
                      variants={fadeInAnimationVariants}
                      initial="first"
                      whileInView="last"
                      transition={{ duration: 1.2, delay: 1.2 }}
                      viewport={{
                        once: true,
                      }}
                      className="text-base sm:text-xl font-sans italic"
                    >
                      {card.secondText}
                    </motion.div>

                    <motion.div
                      variants={fadeInAnimationVariants}
                      initial="firstContact"
                      whileInView="lastContact"
                      viewport={{
                        once: true,
                      }}
                      transition={{ duration: 1.2, delay: 2 }}
                      className="relative mx-auto border-solid border-2 border-white py-3 px-8 sm:px-14 cursor-pointer rounded-lg"
                    >
                      <a href="#CONTACT">
                        <h1 className="font-serif text-lg">CONTACT US</h1>
                        <div className="fill-animation"></div>
                      </a>
                    </motion.div>
                  </Text>

                  <div className="flex mb-2">
                    {[
                      "TBC TECHNOLOGY SOLUTIONS",
                      "Adverstiment Technology",
                      "Software Outsorcing Project",
                      "It Consultancy",
                      "Software Development",
                    ].map((item, index) => (
                      <Button
                        key={item + index}
                        className="w-1 h-1 rounded-[50%] bg-[white] m-1 cursor-pointer hover:bg-[#B68763] transition-colors duration-200 ease-in-out p-1.5"
                        style={
                          card.firstText === item
                            ? { backgroundColor: "#B68763" }
                            : {}
                        }
                        onClick={() => {
                          sliderRef.current?.slickGoTo(index);
                        }}
                      ></Button>
                    ))}
                  </div>
                </Text>
              </CardOne>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default HeroSection;
