import Two from "../../../assets/ERP.jpg"
import integration from "../../../assets/Approach/Integration.png";
import team from "../../../assets/Approach Two/Research.png";
import cleaning from "../../../assets/Approach/Development.png";
import charts from "../../../assets/Approach Two/deployment.png";
import {Hero} from "../../particles/ERPData/ERPInfo"
import HeroSection from "../../templates/HeroSection/HeroSection"
import Services from "../../templates/Services"
import Approach from "../../templates/Approach/Approach"
import FAQ from '../../templates/FAQ'
import { DataTwo } from "../../particles/ERPData/ERPSolution"
import { DataThree } from "../../particles/ERPData/ERPInfo"
import { DataOne } from "../../particles/ERPData/ERPSolution";
import { FaCog, FaSlidersH, FaLink, FaChartLine } from 'react-icons/fa';

export const renderIcons = (element) => {
    switch (element) {
        case 0:
            return <FaCog size={15} />;
        case 1:
            return <FaSlidersH size={15} />;
        case 2:
            return <FaLink size={15} />;
        case 3:
            return <FaChartLine size={15} />;
        default:
            return "";
    }
  }
  export const renderServiceIcon = (element) => {
    switch (element) {
        case 0:
            return team;
        case 1:
            return integration;
        case 2:
            return cleaning;
        case 3:
            return charts;
        default:
            return "";
    }
  }
export {
    Two, HeroSection, Hero, Services, 
    DataThree, Approach, DataTwo, FAQ, 
    DataOne
}