export const DataOne = {
    cards: [
        {
            firstText: "What services do you provide under your Managed Services offering?",
            secondText: "Our Managed Services encompass comprehensive IT management, including network administration, application management, infrastructure maintenance, and security monitoring. We handle the complexities of these areas, allowing you to concentrate on your core business."
        },
        {
            firstText: "How do you ensure the reliability of the systems you manage?",
            secondText: "We employ proactive administration techniques to ensure the reliability of your systems. This includes regular monitoring, proactive maintenance, and timely issue resolution. Our experienced team employs industry best practices to minimize downtime and keep your systems running smoothly."
        },
        {
            firstText: "How do you ensure the security of our systems and data?",
            secondText: "Security is a top priority for us. We implement robust security measures, including firewalls, intrusion detection systems, antivirus software, and regular security updates. We also conduct vulnerability assessments and maintain strict access controls to protect your systems and data from potential threats."
        },
        {
            firstText: "How do you handle incidents and emergencies?",
            secondText: "We have a dedicated team of experts who are available 24/7 to handle incidents and emergencies. We follow established incident response procedures and have escalation protocols in place to ensure prompt resolution of any issues that may arise."
        },
        {
            firstText: "Can you provide examples of successful outcomes from your Managed Services engagements?",
            secondText: "We have a proven track record of delivering successful outcomes for our clients. Our Managed Services have helped businesses achieve improved system performance, increased uptime, enhanced security posture, and streamlined IT operations. We can provide case studies and client references that demonstrate the value we bring."
        }
    ]
}
export const DataTwo = {
    cardsOne: [
     {
         firstText: "Assessment and Planning",
         secondText: "Conduct a thorough assessment of your client's existing IT infrastructure, networks, applications, and security systems."
     },
     {
         firstText: "Implementation and Deployment",
         secondText: "Execute the agreed-upon plan by deploying the necessary resources, tools, and technologies to manage your client's IT services effectively.."
     },
     {
         firstText: "Proactive Monitoring and Maintenance",
         secondText: "Engage in constant communication with the client throughout the development process. Share progress updates, gather feedback, and address any concerns they may have."
     },
     {
         firstText: "Support and Continuous Improvement",
         secondText: "Provide responsive and reliable technical support to address any IT-related issues, incidents, or service requests from the client."
     }
    ]
 }