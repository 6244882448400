import { Text } from "../../atoms/Text";
import { Image } from "../../atoms/Image";
import { List } from "../../atoms/List";
import Ablak from "../../../assets/ablak_logo.png";
import { NavLinks } from "../../particles/NavLinks";
import { MdMail } from "react-icons/md";
import { FaUsers, FaHome, FaQuestionCircle, FaQuestion } from "react-icons/fa";
import { BsPuzzleFill } from "react-icons/bs";
import { AiOutlineComment } from "react-icons/ai";
import { BsInfoCircleFill } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { MdTimeline } from "react-icons/md";
export const renderIcons = (element) => {
  switch (element) {
    case 0:
      return <FaHome size={24} color="#0c5db4" />;
    case 1:
      return <BsPuzzleFill size={24} color="#0c5db4" />;
    case 2:
      return <FaQuestion size={24} color="#0c5db4" />;
    case 2:
      return <AiOutlineComment size={24} color="#0c5db4" />;
    case 3:
      return <FaUsers size={24} color="#0c5db4" />;
    case 4:
      return <BsInfoCircleFill size={24} color="#0c5db4" />;
    case 5:
      return <FaQuestionCircle size={24} color="#0c5db4" />;
    case 6:
      return <FiMail size={24} color="#0c5db4" />;
    case 7:
      return <MdTimeline size={24} color="#0c5db4" />;
    case 8:
      return <MdMail size={24} color="#0c5db4" />;
    default:
      return "";
  }
};
export { Text, Image, List, Ablak, NavLinks };
