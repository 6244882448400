import {  Hero, Two, HeroSection,
    DataThree, Services, renderIcons,
    Approach, DataTwo, renderServiceIcon,
    FAQ, DataOne} from "./EnginneringInfo"
import NewsLetter from "../../templates/Contact"
const Engineering = () =>{
    const word= "Your Data Enginnering"
    return (
       <>
       <HeroSection Hero={Hero} profile={Two}/>
       <Services DataThree={DataThree} renderIcons={renderIcons} word={word} />
       <Approach DataTwo={DataTwo} renderServiceIcon={renderServiceIcon}/>
       <FAQ DataOne={DataOne}/>
       <NewsLetter/>
       </>
    )
}

export default Engineering;