export const DataOne = {
  cards: [
    {
      firstText: "Ablak Solutions",
      secondText:
        "With 10+ Years Of Expertise In Technology, We Continuously Push Boundaries In Software Development.",
    },
    {
      firstText: "It Consultancy",
      secondText:
        "Navigate the complexities of the digital landscape with our trusted IT Consultancy services, providing strategic guidance and tailored solutions to optimize your technology infrastructure.",
    },
    {
      firstText: "Software Development",
      secondText:
        "Unlock innovation and drive your business forward with our comprehensive Software Development solutions, delivering custom-built applications that exceed your expectations.",
    },
  ],
};
