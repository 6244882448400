import { useRef } from "react";
import { motion } from "framer-motion";
import {
  Button,
  TestimonialTexts,
  Card,
  Text,
  renderProfileImg,
  settings,
} from "./index";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./Clients.css";

const Clients = () => {
  const sliderRef = useRef<Slider | null>();
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <section
      id="CLIENTS"
      className="relative w-full  bg-gradient-to-l from-gray-100 to-gray-200 flex flex-col items-center py-[40px] md:py-[100px]"
    >
      <Text className="uppercase text-center font-serif font-semibold text-lg sm:text-2xl text-[#25C7D5] italic">
        Our Clients
      </Text>
      <Slider
        ref={(slider) => (sliderRef.current = slider)}
        {...settings}
        className="w-[80%] mt-8 ml-[60px]"
      >
        {TestimonialTexts.feedBacks.map((feedback, index) => (
          <Card
            cardClass=" bg-white h-[250px] flex flex-col cursor-pointer hover:shadow-xl rounded-xl p-2 md:p-8"
            imageWrapperClass="h-[70%] relative"
            imageAlt="feedback"
            imageSrc={renderProfileImg(index)}
            textWrapperClass="mt-2 w-full flex flex-col items-center text-center"
            key={index}
          >
            <Text className="w-[70%] mx-auto flex flex-col items-center mt-4 gap-2 ">
              <Text>
                <h4 className="font-base font-serif">{feedback.location}</h4>
              </Text>
            </Text>
          </Card>
        ))}
      </Slider>
      <div className="absolute  left-2 sm:left-7 right-2 sm:right-7  flex justify-between items-center h-full sm:h-[80%]">
        <Text>
          <Button
            onClick={previous}
            id="prev"
            className="transition ease-in cursor-pointer outline-none border-none p-2 text-[#25C7D5] rounded-full bg-white hover:text-white hover:bg-gray-900"
          >
            <FaArrowLeft size={24} />
          </Button>
        </Text>
        <Text>
          <Button
            onClick={next}
            id="next"
            className="transition ease-in cursor-pointer outline-none border-none p-2 rounded-full bg-white text-[#25C7D5] hover:bg-gray-900 hover:text-white"
          >
            <FaArrowRight size={24} />
          </Button>
        </Text>
      </div>
    </section>
  );
};
export default Clients;
