import {HeroSection, Two, Hero,
    Services, DataThree, renderIcons,
    Testimonies, TestimonialTexts, renderProfileImg,
    DataTwo, Approach,  renderServiceIcon, FAQ, DataOne
} from "./MobileImage"
import NewsLetter from "../../templates/Contact";

const Mobile_Development = ()=>{
    const word = "Mobile Application"
    return (
       <>
       <HeroSection Hero={Hero} profile={Two}/>
       <Services word={word} DataThree={DataThree} renderIcons={renderIcons}/>
       <Testimonies TestimonialTexts={TestimonialTexts} renderProfileImg={renderProfileImg} />
       <Approach DataTwo={DataTwo}  renderServiceIcon={ renderServiceIcon}/>
       <FAQ DataOne={DataOne}/>
       <NewsLetter/>
       </>
    )
}
export default Mobile_Development