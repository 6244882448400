export const NavLinks = [
  {
    name: "HOME",
    url: "home",
  },
  {
    name: "ABOUT US",
    url: "about us",
  },
  {
    name: "WHY US",
    url: "why us",
  },
  {
    name: "SOLUTIONS",
    url: "solutions",
  },
  {
    name: "TESTIMONIES",
    url: "testimonies",
  },
  {
    name: "CLIENTS",
    url: "clients",
  },
  {
    name: "APPROACH",
    url: "approach",
  },
  {
    name: "FAQ",
    url: "faq",
  },

  {
    name: "CONTACT",
    url: "contact",
  },
];
