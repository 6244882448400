import {
  HeroSection,
  Hero,
  Two,
  Services,
  DataThree,
  renderIcons,
  FAQ,
  DataOne,
  Approach,
  DataTwo,
  renderServiceIcon,
} from "./Services";
import NewsLetter from "../../templates/Contact";
const ManagingServices = () => {
  const word = "Networking Services";
  return (
    <>
      <HeroSection Hero={Hero} profile={Two} />
      <Services DataThree={DataThree} renderIcons={renderIcons} word={word} />
      <Approach DataTwo={DataTwo} renderServiceIcon={renderServiceIcon} />
      <FAQ DataOne={DataOne} />
      <NewsLetter />
    </>
  );
};
export default ManagingServices;
