import { TestimonialTexts } from "../../particles/Testimonies";
import ProfileImg1 from "../../../assets/EngidaOne.png";
import ProfileImg2 from "../../../assets/TBCONE.png";
import ProfileImg4 from "../../../assets/Excellence.png";

export const renderProfileImg = (element) => {
  switch (element) {
    case 0:
      return ProfileImg2;
    case 1:
      return ProfileImg1;
    case 2:
      return ProfileImg4;
    default:
      return "";
  }
};

export { TestimonialTexts };
