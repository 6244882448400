import { useState} from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Text, ServiceTexts, Card, renderServiceIcon } from "./index";
import "./Services.css";

const fadeInAnimationVariants = {
  first: {
    scale: 0,
    opacity: 0
  },
  last: {
    scale: 1,
    opacity: 1
  }
}
const Services = () => {
  const [expandedCards, setExpandedCards] = useState<Array<number | boolean>>(() => {
    const length = ServiceTexts.cards.length;
    const initialArray: Array<number | boolean> = [];
    for (let i = 0; i < length; i++) {
      initialArray.push(false);
    }
    return initialArray;
  });

  const handleRead = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    setExpandedCards((prev) => {
      const newExpandedCards = [...prev];
      newExpandedCards[index] = !newExpandedCards[index];
      return newExpandedCards;
    });
  };

  const truncateText = (text: string, characterLimit: number) => {
    if (text.length <= characterLimit) {
      return text;
    }

    const truncatedText = text.substr(0, characterLimit);
    const lastPeriodIndex = truncatedText.lastIndexOf(".");
    if (lastPeriodIndex !== -1) {
      return truncatedText.substr(0, lastPeriodIndex + 1);
    }

    return truncatedText;
  };

  return (
    <section
    id="SOLUTIONS"
    className="w-full flex flex-col items-center justify-center bg-gradient-to-l from-gray-100 to-gray-200 pb-12 relative"
    >
      <main>
        <motion.div
        variants={fadeInAnimationVariants}
        initial="first"
        whileInView="last"
       
        viewport={{
          once: true
        }} 
        className="w-[100vw] pt-14 flex flex-col gap-3 items-center px-6">
          <Text  className="font-semibold font-serif text-3xl md:text-4xl text-[#25C7D5]">
            {ServiceTexts.firstText}
          </Text>
          <Text
                className='w-[100px] mx-auto border-2 border-sold border-[#25C7D5] mb-3'
                >
                       
          </Text>
          <Text  className="text-base font-medium text-[#4F717F]">
            {ServiceTexts.secondText}
          </Text>

          <div className="flex flex-wrap justify-center ">
            {ServiceTexts.cards.map((card, index) => (
              <Link key={index} to={`/services/${card.firstText}`} className="w-[90%] sm:w-[45%] lg:w-[30%] h-[30%] my-2 mx-4">
                <Card
                  cardClass="w-full bg-white flex flex-col items-center justify-center cursor-pointer hover:shadow-xl rounded-xl border-solid border-2 p-2"
                  imageWrapperClass="w-full h-[250px] zoom-in-out-box relative"
                  imageAlt={card.firstText}
                  imageSrc={renderServiceIcon(index)}
                  textWrapperClass="mt-2 w-full flex flex-col items-center text-center"
                >
                  <Text as="h4" className="font-serif uppercase rounded">
                    {card.firstText}
                  </Text>

                  <Text className="text-sm font-light flex flex-col p-2">
                    <p>
                      {expandedCards[index] ? (
                        card.secondText
                      ) : (
                        truncateText(card.secondText, 180)
                      )}{" "}
                      <span>
                        {" "}
                        <button className="font-semibold hover:underline" onClick={(e) => handleRead(e, index)}>
                          {expandedCards[index] ? "Less" : "Read More"}
                        </button>{" "}
                      </span>
                    </p>
                  </Text>
                </Card>
              </Link>
            ))}
          </div>
        </motion.div>
      </main>
    </section>
  );
};

export default Services;