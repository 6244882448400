import { motion } from "framer-motion";
import { CardOne } from "../../molecules/CardOne";
import { Text } from "../../atoms/Text";
import "./HeroSection.css";

interface FAQProps {
  Hero: {
    firstText: string;
    secondText: string;
  };
  profile: string;
}
const fadeInAnimationVariants = {
  firstContact: {
    opacity: 0,
    y: 100,
  },
  lastContact: {
    opacity: 1,
    y: 1,
  },
  first: {
    opacity: 0,
    x: 100,
  },
  last: {
    opacity: 1,
    x: 0,
  },
  initial: {
    opacity: 0,
    x: -100,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
};
const HeroSection = ({ Hero, profile }: FAQProps) => {
  return (
    <div id="HOME" className="w-full mt-[90px]">
      <CardOne cardClass="h-[500px] md:h-auto relative" cardSrc={profile}>
        <Text className="flex flex-col  justify-between max-w-5xl mx-auto h-[500px] md:h-[700px] items-center text-center text-white">
          <Text className="flex flex-col justify-center h-full gap-4 px-2 lg:px-0">
            <motion.div
              variants={fadeInAnimationVariants}
              initial="initial"
              whileInView="animate"
              transition={{ duration: 1.2 }}
              viewport={{
                once: true,
              }}
              className="text-3xl sm:text-4xl md:text-6xl font-serif font-bold"
            >
              {Hero.firstText}
            </motion.div>
            <motion.div
              variants={fadeInAnimationVariants}
              initial="first"
              whileInView="last"
              transition={{ duration: 1.2, delay: 1.2 }}
              viewport={{
                once: true,
              }}
              className=" text-base sm:text-xl font-sans italic"
            >
              {Hero.secondText}
            </motion.div>

            <motion.div
              variants={fadeInAnimationVariants}
              initial="firstContact"
              whileInView="lastContact"
              viewport={{
                once: true,
              }}
              transition={{ duration: 1.2, delay: 2 }}
              className="relative  max-w-sm mx-auto border-solid border-2 border-white py-3 px-8 sm:px-14 cursor-pointer rounded-lg"
            >
              <a href="#CONTACT">
                <h1 className="font-serif text-lg">CONTACT US</h1>
                <div className="fill-animation"></div>
              </a>
            </motion.div>
          </Text>
        </Text>
      </CardOne>
    </div>
  );
};
export default HeroSection;
