export const TestimonialTexts = {
  firstText: "Testimonies From Our Clients",
  secondText: "What People Say About Us",
  feedBacks: [
    {
      text: "Outstanding IT support, surveying, and data analysis from TBC Solutions. They streamlined our operations and exceeded expectations. Highly recommended!",
      location: "Ablak SOLUTIONS",
    },
    {
      text: "We had a great experience with their IT support, surveying, and data analysis services.Their support was crucial in optimizing our operations, and we were impressed by their commitment to excellence.",
      location: "EXCELLENCE CONSULTANCY",
    },
  ],
};
