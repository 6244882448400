import HeroSection from "../../templates/HeroSection/HeroSection"
import Services from "../../templates/Services"
import Approach from "../../templates/Approach/Approach"
import FAQ from "../../templates/FAQ"
import { DataOne } from "../../particles/EnginneringData/DataInfo";
import { DataThree } from "../../particles/EnginneringData/DataInfo";
import { DataTwo } from "../../particles/EnginneringData/DataSolution";
import { Hero } from "../../particles/EnginneringData/DataInfo";
import Two from "../../../assets/DataTest.jpg";
import { FaCheckCircle, FaPuzzlePiece,  FaRocket, FaLock} from 'react-icons/fa';
import integration from "../../../assets/Approach/Integration.png";
import team from "../../../assets/Approach Two/Research.png";
import storage from "../../../assets/Approach/Storage.png";
import charts from "../../../assets/Approach/Charts.png";
export const renderIcons = (element) => {
    switch (element) {
        case 0:
            return <FaCheckCircle size={15} color="currentColor"  />;
        case 1:
            return <FaPuzzlePiece size={15} color="currentColor" />;
        case 2:
            return <FaRocket size={15} color="currentColor" />;
        case 3:
            return <FaLock  size={15} color="currentColor"  />;
        default:
            return "";
    }
  }

  export const renderServiceIcon = (element) => {
    switch (element) {
        case 0:
            return team;
        case 1:
            return integration;
        
        case 2:
            return  storage;
        case 3:
            return charts;
        default:
            return "";
    }
  }
export {
    Hero, Two, HeroSection,
    DataThree, Services, Approach,
    DataTwo, FAQ, DataOne
}