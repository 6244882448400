import { useState } from "react";
import { motion, AnimatePresence} from "framer-motion";
import { Text } from "../atoms/Text";
import { Button } from "../atoms/Button";
import {AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

interface FAQProps {
    DataOne: {
    cards: {
      firstText: string;
      secondText: string;
    }[];
  };
}

const FAQ = ({ DataOne }: FAQProps) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleCardClick = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section id="FAQ" className="w-full bg-gradient-to-b from-gray-100 to-gray-100 md:pb-[100px] border-b-[1.8px]">
      <main className="w-[90%]  flex flex-col gap-12 mx-auto">
        <Text className="text-[#25C7D5] w-full text-center text-3xl font-serif mt-[50px] font-bold">
          FAQ
        </Text>
        <ul className="w-full flex flex-col gap-4">
          {DataOne.cards.map((card, index) => (
            <Text
              className="w-full flex flex-col border-solid border-2 rounded-xl bg-white shadow-sm p-4 cursor-pointer"
              style={{ transition: "max-height 0.3s ease" }}
              key={index}
            >
              <Text className="w-full flex justify-between mb-6">
                <Text className="text-lg md:text-2xl font-serif text-[#4F717F]">{card.firstText}</Text>
                <Button onClick={() => handleCardClick(index)}>
                  {expandedIndex === index ? (
                    <motion.div
                      initial={{ rotate: 0 }}
                      animate={{ rotate: 180 }}
                      transition={{ duration: 0.3 }}
                    >
                      <AiOutlineMinus color="#4F717F"/>
                    </motion.div>
                  ) : (
                    <motion.div
                      initial={{ rotate: 0 }}
                      animate={{ rotate: 180 }}
                      transition={{ duration: 0.3 }}
                    >
                      <AiOutlinePlus color="#4F717F"/>
                      
                    </motion.div>
                  )}
                </Button>
              </Text>
              <AnimatePresence>
              {expandedIndex === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  transition={{ duration: 0.3 }}
                  exit={{ opacity: 0, height: 0 }}
                  className=" text-[#4F717F] mb-2 text-xs md:text-lg font-serif"
                >
                  {card.secondText}
                </motion.div>
              )}
              </AnimatePresence>
            
              
            </Text>
          ))}
        </ul>
      </main>
    </section>
  );
};

export default FAQ;