import { useRef } from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Button } from "../../atoms/Button";
import { Card } from "../../molecules/Card";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { Text } from "../../atoms/Text";
interface FAQProps {
  TestimonialTexts: {
    firstText: string;
    secondText: string;
    feedBacks: {
      text: string;
      location: string;
    }[];
  };
  renderProfileImg: (element: number) => string;
}
const Testimonies = ({ TestimonialTexts, renderProfileImg }: FAQProps) => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
  };
  const sliderRef = useRef<Slider | null>();
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
      id="TESTIMONIES"
      className="border-t-[1.8px] relative w-full bg-gradient-to-b from-gray-100 to-gray-100 flex flex-col items-center justify-center py-[40px] sm:py-[100px]"
    >
      <Text className="flex flex-col gap-4">
        <Text
          as="h1"
          className="font-semibold font-serif text-xl sm:text-4xl text-[#25C7D5]"
        >
          {TestimonialTexts.firstText}
        </Text>
        <Text className="w-[50px] sm:w-[80px] mx-auto border-2 border-sold border-[#25C7D5] mb-3"></Text>
        <Text
          as="p"
          className="text-sm sm:text-base font-medium text-[#4F717F] text-center"
        >
          {TestimonialTexts.secondText}
        </Text>
      </Text>
      <Slider
        ref={(slider) => (sliderRef.current = slider)}
        {...settings}
        className="w-full lg:w-[80%]"
      >
        {TestimonialTexts.feedBacks.map((feedback, index) => (
          <Card
            key={index}
            cardClass="pt-6 ml-[50px] md:ml-[100px] mx-auto"
            imageAlt="Hello"
            imageSrc={renderProfileImg(index)}
            imageWrapperClass="w-[50%] md:w-[35%] mx-auto"
            cover="object-fit"
          >
            <Text className="mx-auto flex flex-col items-center mt-4 gap-2 ">
              <Text>
                <h4 className="font-base font-serif">{feedback.location}</h4>
              </Text>
              <Text>
                <FaQuoteLeft className="hidden lg:inline-block mb-4" />
                <h4 className="font-serif text-xs md:text-lg">
                  {feedback.text}
                </h4>
                <div className="w-full flex flex-col items-end">
                  <FaQuoteRight className="hidden lg:inline-block mt-4" />
                </div>
              </Text>
            </Text>
          </Card>
        ))}
      </Slider>
      <div className=" absolute  left-7 right-0 md:right-7  flex justify-between items-center h-full">
        <Text>
          <Button
            onClick={previous}
            id="prev"
            className="transition ease-in cursor-pointer outline-none border-none p-2 text-[#25C7D5] rounded-full bg-white hover:text-white hover:bg-gray-900"
          >
            <FaArrowLeft size={24} />
          </Button>
        </Text>
        <Text>
          <Button
            onClick={next}
            id="next"
            className="transition ease-in cursor-pointer outline-none border-none p-2 rounded-full bg-white text-[#25C7D5] hover:bg-gray-900 hover:text-white"
          >
            <FaArrowRight size={24} />
          </Button>
        </Text>
      </div>
    </motion.div>
  );
};
export default Testimonies;
