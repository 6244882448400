import { FormEvent, useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { Text } from "../atoms/Text";
import Alert from "react-bootstrap/Alert";
import { motion } from "framer-motion";
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa";
const NewsLetter = () => {
  const [isLoading, setLoading] = useState(true);
  const [isAlert, setAlert] = useState(false);
  const [alertColor, setAlertColor] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState("");
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(false);
    if (form.current) {
      emailjs
        .sendForm(
          "service_7q98i84",
          "template_nb02r3t",
          form.current,
          "YvHMh0v59uU5JOosO"
        )
        .then((result) => {
          if (result.text === "OK") {
            setAlert(true);
            setLoading(true);
            setAlertColor("#D4EDDA");
            setAlertMessage("Your Message has been sent Successfully!");
          } else {
            // Handle other cases if needed
          }
        })
        .catch((error) => {
          console.log(error.text);
        });
    }
  };

  useEffect(() => {
    if (isAlert) {
      const timeout = setTimeout(() => {
        setAlert(false);
      }, 3000); // Adjust the timeout duration (in milliseconds) as needed
      return () => clearTimeout(timeout);
    }
  }, [isAlert]);

  const Alerttype = (color: string | null, message: string) => {
    return <Alert className={`p-3 rounded-lg bg-[${color}]`}>{message}</Alert>;
  };

  return (
    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
      id="CONTACT"
      className="flex  flex-col items-center w-full relative  md:h-[550px]  bg-gradient-to-b from-gray-100 to-gray-100 py-4 md:py-0"
    >
      <h1 className=" uppercase text-2xl md:text-4xl text-center font-bold font-serif text-[#25C7D5] mt-8">
        Get In Touch Now!
      </h1>

      <main className="w-[90%] mx-auto flex flex-col md:flex-row  justify-between gap-4 mt-4 ">
        <div className="w-full md:w-[50%]  py-6 flex flex-col gap-6 ">
          <Text className=" cursor-pointer flex flex-col items-center  bg-white w-[90%]  mx-auto rounded-lg shadow-md pt-4 gap-2 pb-4">
            <Text className="flex flex-col items-center justify-center rounded-[50%] p-2 border-dashed border-2 border-[#96E6F0] ">
              <motion.div
                whileInView={{ rotateY: [0, 180, 0] }}
                transition={{ duration: 2, ease: "linear" }}
              >
                <FaMapMarkerAlt size={30} color="#25C7D5" />
              </motion.div>
            </Text>
            <h1 className="w-full text-lg text-center md:text-2xl font-serif text-[#4F717F]">
              Our Address
            </h1>
            <p className="px-4 text-sm font-serif text-[#4F717F]">
              22 Next to the Capital Hotel, Addis Ababa, Ethiopia Gelila
              Building, Second Floor
            </p>
          </Text>

          <Text className="flex flex-col md:flex-row gap-6 w-[90%] mx-auto">
            <Text className="cursor-pointer w-full md:w-[50%] flex flex-col items-center  bg-white  mx-auto rounded-lg shadow-md pt-4 gap-2 pb-4">
              <Text className="flex flex-col items-center justify-center rounded-[50%] p-4 border-dashed border-2 border-[#96E6F0] ">
                <motion.div
                  whileInView={{ scale: [0, 1, 2, 1] }}
                  transition={{ duration: 0.8, ease: "linear" }}
                >
                  <FaEnvelope size={30} color="#25C7D5" />
                </motion.div>
              </Text>
              <h1 className="text-lg md:text-2xl font-serif text-[#4F717F]">
                Email Us
              </h1>
              <p className="px-4 text-sm font-serif text-[#4F717F]">
                info@ablaksolutions.com
              </p>
            </Text>

            <Text className=" cursor-pointer w-full md:w-[50%] flex flex-col items-center  bg-white  mx-auto rounded-lg shadow-md pt-4 gap-2 pb-4">
              <Text className="flex flex-col items-center justify-center rounded-[50%] p-4 border-dashed border-2 border-[#96E6F0] ">
                <motion.div
                  whileInView={{ scale: [1, 1.1, 1, 1.1, 1, 1.1, 1, 1.1] }}
                  transition={{ duration: 0.5, ease: "linear" }}
                >
                  <FaPhone size={30} color="#25C7D5" />
                </motion.div>
              </Text>
              <h1 className="text-lg md:text-2xl font-serif text-[#4F717F]">
                Call Us
              </h1>
              <p className="px-4 text-sm font-serif text-[#4F717F]">
                +251979060679
              </p>
            </Text>
          </Text>
        </div>

        <div className="w-full md:w-[50%]">
          {isAlert && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className=" bg-[#D4EDDA]  "
            >
              {Alerttype(alertColor, alertMessage)}
            </motion.div>
          )}
          <form
            ref={form}
            className="w-full flex flex-col md:flex-row justify-between md:flex-wrap gap-5 md:gap-4 md:py-6 h-full items-center relative"
            onSubmit={sendEmail}
          >
            <input
              type="text"
              name="user_name"
              placeholder="Your Name"
              className="p-5 border rounded-lg text-xs md:text-base shadow-lg outline-none w-full md:w-[45%]"
              required
            />
            <input
              type="email"
              placeholder="Email"
              name="user_email"
              className="p-5  border rounded-lg text-xs md:text-base shadow-lg outline-none w-full md:w-[45%]"
              required
            />
            <input
              type="text"
              placeholder="Your Company(optional)"
              name="user_company"
              className="p-5  border rounded-lg text-xs md:text-base  shadow-lg outline-none w-full md:w-[45%]"
              required
            />
            <input
              type="text"
              placeholder="Your Subject"
              name="message"
              className="p-5  border rounded-lg text-xs md:text-base shadow-lg outline-none w-full md:w-[45%]"
              required
            />

            <textarea
              placeholder="Please Add Your Message Here"
              name="user_subject"
              className="p-12 text-xs md:text-sm border rounded-lg  w-full h-[40%] shadow-lg outline-none uppercase"
              required
            />
            <Text className=" mt-2 w-full flex flex-col items-center">
              <button
                type="submit"
                value="Send"
                className="bg-[#FF9900]  w-full md:w-auto pt-2 pb-2 pl-[60px] pr-[60px] rounded-lg text-sm md:text-base font-serif"
              >
                {isLoading ? "Send Message" : "Sending ..."}
              </button>
            </Text>
          </form>
        </div>
      </main>
    </motion.div>
  );
};

export default NewsLetter;
