export const TestimonialTexts = {
  firstText: "Testimonies From Our Clients",
  secondText: "What People Say About Us",
  feedBacks: [
    {
      text: "TBC Technology Solutions' mobile app is a game-changer! It's intuitive, sleek, and has significantly improved our productivity.",
      location: "TBC TECHNOLOGY",
    },
    {
      text: "ENGIDA's bus ticket booking app is a true gem! It's seamless, reliable, and has made our travel experience hassle-free.",
      location: "ENGIDA SOLUTION",
    },
  ],
};
export const DataTwo = {
  cardsOne: [
    {
      firstText: "Project  Planning",
      secondText:
        "Analyze client requirements, goals, and project scope. Plan resources, timelines, and deliverables.",
    },
    {
      firstText: "Development & Testing",
      secondText:
        "Start the development process according to the project plan. This includes coding, designing, and implementing the required software or mobile application.",
    },
    {
      firstText: "Client  Feedback",
      secondText:
        "Engage in constant communication with the client throughout the development process. Share progress updates, gather feedback, and address any concerns they may have.",
    },
    {
      firstText: "Deployment and Support",
      secondText:
        " Deploy the software or app to desired platforms. Provide ongoing support, updates, and maintenance. Address post-deployment issues promptly for customer satisfaction.",
    },
  ],
};
export const DataOne = {
  cards: [
    {
      firstText: "Can you provide  examples of your previous mobile projects?",
      secondText:
        "Certainly! We can provide references from satisfied clients and share relevant examples of our successful mobile app projects to demonstrate our expertise and the quality of our work. Just let us know, and we'll be happy to provide you with the requested information.",
    },
    {
      firstText:
        "How do you handle app updates and maintenance post-development?",
      secondText:
        "We provide comprehensive post-development support, offering regular updates, addressing bug fixes promptly, and ensuring your mobile app remains up-to-date and functioning smoothly.",
    },
    {
      firstText:
        "will my mobile app be compatible with various devices and screen sizes?",
      secondText:
        "Yes, we ensure cross-device and cross-platform compatibility by employing responsive design techniques and conducting thorough testing on various devices and screen sizes, ensuring a consistent and optimized user experience.",
    },
    {
      firstText:
        "How do you ensure data security and user privacy in mobile app development?",
      secondText:
        "We prioritize data security and user privacy by implementing industry best practices, robust encryption methods, and strict compliance with relevant data protection regulations, ensuring the confidentiality and integrity of user information.",
    },
    {
      firstText:
        " How can I give feedback or request changes during development?",
      secondText:
        " You can easily provide feedback and request changes through our project management tool, ensuring seamless communication and efficient collaboration between our team and yours.",
    },
  ],
};
