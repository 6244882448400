import {Text} from "../../atoms/Text"
import {Card} from "../../molecules/Card"
import { ServiceTexts } from "../../particles/Services"
import SoftwareEight from "../../../assets/SoftwareEight.jpg"
import SoftwareThree from "../../../assets/SoftwareThree.jpg"
import TestTwo from "../../../assets/TestTwo.jpg"
import TestFive from "../../../assets/TestFive.jpeg"
import MobileFive from "../../../assets/MobileTest.jpg"
import ERP from "../../../assets/ERP.jpg"
export const renderServiceIcon = (element) => {
    switch (element) {
        case 0:
            return TestFive;
        case 1:
            return SoftwareThree;
        case 2:
            return MobileFive;
           
        case 3:
            return ERP;
        case 4:
            return SoftwareEight;
        case 5:
            return TestTwo;
        default:
            return "";
    }
}
export {
    Text , ServiceTexts, Card
}