import { Hero } from "../../particles/SoftwareData/SoftwareInfo";
import TestFive from "../../../assets/TestFive.jpeg"
import HeroSection from "../../templates/HeroSection/HeroSection";
import Services from "../../templates/Services";
import Approach from "../../templates/Approach/Approach";
import { DataThree } from "../../particles/SoftwareData/SoftwareInfo";
import { FaStar, FaCog, FaLink, FaWrench } from "react-icons/fa";
import FAQ from "../../templates/FAQ";
import { DataOne } from "../../particles/SoftwareData/FAQ";
import { DataTwo } from "../../particles/SoftwareData/SoftwareSolution";
import planning from "../../../assets/Approach/Planning.png"
import development from "../../../assets/Approach/Development.png";
import deployment from "../../../assets/Approach Two/deployment.png";
import Support from "../../../assets/Approach/Support.png";
export const renderIcons = (element) => {
    switch (element) {
        case 0:
            return <FaStar size={15} color="currentColor" />;
        case 1:
            return <FaCog size={15} color="currentColor"  />;
        case 2:
            return <FaLink size={15} color="currentColor" />;
        case 3:
            return <FaWrench size={15} color="currentColor"/>;
        default:
            return "";
    }
  }
  export const renderServiceIcon = (element) => {
    switch (element) {
        case 0:
            return planning;
        case 1:
            return development;  
        case 2:
            return  Support;
        case 3:
            return deployment;
        default:
            return "";
    }
  }
  export {
    Hero, TestFive, 
    HeroSection, DataThree, Services,
    FAQ, DataOne,  DataTwo, Approach
}